import React, { useCallback, useEffect, useState } from 'react';
import { Typography, PageContainer } from '../../components';

import { BancoDeTalentosContainer } from './BancoDeTalentosStyled'
import { GreyContainerContainer } from '../../components/Structure/GreyContainer/GreyContainerStyled';
import { InnerContentContainerContainer } from '../../components/Structure/InnerContentContainer/InnerContentContainerStyled';
import CurriculossService from '../../services/curriculos.service';
import { ICurriculo } from '../../types/curriculos';
import { IIBGEState } from '../../components/features/Student/UpdateStudentProfile';
import Axios from 'axios';

export interface Props {
}

const BancoDeTalentos: React.FC<Props> = ({
  children,
  ...props
}) => {

  const [activeCurr, setActiveCurr] = useState<ICurriculo>({} as ICurriculo);
  const [curriculos, setCurriculos] = useState<ICurriculo[]>([]);
  const [loading, setLoading] = useState(true);
  const [states, setStates] = useState([""]);
	const [lastPage, setLastPage] = useState(false);
	const [curriculossNextPage, setCurriculosNextPage] = useState(1)
	const [curriculosCount, setCurriculosCount] = useState(0);
	const [viewMoreDisabled, setViewMoreDisabled] = useState(false);

  const getCurriculos = useCallback(async (state = "") => {
		const curriculosService = new CurriculossService();
		const response = await curriculosService.listCurriculos(state);

    console.log(response)

		if (response.next) {
			setCurriculosNextPage(response.next.split("=")[1]);
			setViewMoreDisabled(false);
			setLastPage(false);
		} else {
			setViewMoreDisabled(true);
			setLastPage(true);
		}

		setCurriculos(response.results);
		setLoading(false);
	}, []);

  useEffect(()=> { getCurriculos(); }, [getCurriculos])


	useEffect(() => {
		try {
			Axios.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados").then((response) => {
				const foundStates = response.data.map((IBGEstate: IIBGEState) => IBGEstate.sigla).sort();
				setStates(foundStates);
			});
		} catch (error) {
			console.error("Houve um erro ao popular os estados")
		}
	}, []);

  const openCurriculoModal = (e:any ,curriculo: ICurriculo) => {
    e.preventDefault();
    setActiveCurr(curriculo);
  }

  const closeCurriculoModal = () => {
    setActiveCurr({} as ICurriculo);
  }

  return (
    <PageContainer
      pageTitle="Banco de Talentos"
      showSearch={false}
      showBreadcrumb>
      <GreyContainerContainer>
        <InnerContentContainerContainer>
      <BancoDeTalentosContainer {...props}>
        <div className="filtros">
          <div className="filtro">
            <label htmlFor="estado">Estado:</label>
          <select name='estado' onChange={(e) => getCurriculos(e.target.value)}>
              <option value="">Todos os Estados</option>
              {states.map((uf: string)=> <option value={uf}>{uf}</option>)}
          </select>
          </div>
        </div>
        <div className="flexCandidatos">
        {
          curriculos.map((curriculo:ICurriculo, index: number) => <a key={index} href='#' onClick={(e) => openCurriculoModal(e, curriculo)} className='curriculoCard'>
            
            {curriculo.foto &&
              <span className="foto"><img src={curriculo.foto} alt={curriculo.usuario} /></span>
            }
            <div className="data">
            <big className="nome">{curriculo.usuario}</big>
            <address>
              <span>{curriculo.cidade} / {curriculo.uf}</span>
            </address>
            </div>
          </a>)
        }
        </div>
        
        {
          activeCurr.usuario && <div className='modalCurriculo'>
            <div className="overlay" onClick={closeCurriculoModal}></div>
            <div className="modalCurriculoBody">
                
            {activeCurr.foto &&
              <span className="foto"><img src={activeCurr.foto} alt={activeCurr.usuario} /></span>
            }
              <div className="topo">
                <h2>{activeCurr.usuario}</h2>
                <span><a href={`mailto:${activeCurr.email}`}>{activeCurr.email}</a> - {activeCurr.telefone}</span>
              </div>
              <div className="corpo">
                <p dangerouslySetInnerHTML={{__html: activeCurr.texto}}></p>
              </div>
              <div className="caracteristicas">
                <div>
                  <b>Profissão: </b>{activeCurr.profissao}
                </div>
                <div>
                  <b>Local: </b>{activeCurr.cidade} / {activeCurr.uf}
                </div>
                <div>
                  <b>Disponível para mudança?</b> {activeCurr.mudancas}
                </div>
                <div>
                  <b>Cursos Concluídos: </b> 
                  <ul>
                    {activeCurr.cursos_concluidos.map((curso: string, index: number) => <li key={index}>{curso}</li>)}
                  </ul>
                </div>
              </div>
              <div className="downloadPdf"><a href={activeCurr.arquivo} target='_blank'>Ver Currículo Completo</a></div>
            </div>
          </div>
        }

      </BancoDeTalentosContainer>
      </InnerContentContainerContainer>
      </GreyContainerContainer>
    </PageContainer>
  );
};

export { BancoDeTalentos };
